import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";

// css
import "../../../Css/UserDealerProfile.css";

// component
import Navbar from "../../Navbar";
import UserProfileSearchbar from "../../UserProfileSearchbar";
import UserProfileNavbar from "../UserProfileNavbar";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// image
import customer__profile__img from "../../../Image/customer__profile__img.PNG";

// api url
import { apiUrl } from "../../../Private";

const UserProfile = () => {
  const [userData, setUserData] = useState({"ProfilePic" : customer__profile__img});

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get user data
  useEffect(() => {
    const profileUrl = `${apiUrl}/v3/api/view-profile/customer/${apiKey['tokens']['refresh']}`;
    // const profileUrl = `${apiUrl}/v3/api/view-profile/customer/${apiKey['id']}/`;

    axios.get(profileUrl, null , {
      headers : {
      'Content-Type' : 'application/json',
      'Accept' : 'application/json',
      'Authorization' : `${apiKey['tokens']['refresh']}`
  }})
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Navbar />

      <UserProfileSearchbar />

      <UserProfileNavbar />

      {userData !== undefined ? (
        <div className="user__dealer__profile">
          <div className="left__side">
            <img src={userData.ProfilePic} alt="" />
            <NavLink
              className="user__dealer__profile__edit__link"
              to="/sell/user/profile/profileedit"
            >
              Edit
            </NavLink>
          </div>
          <div className="right__side">
            {/* <p>
              Name : <span>{userData.username}</span>
            </p> */}
            <p>
              Email ID : <span>{userData.email}</span>
            </p>
            <p>
              Mobile Number : <span>{userData.mobile_number}</span>
            </p>
            <p>
              Account Type : <span>{userData.account_type}</span>
            </p>
            {/* <p>
              Pincode : <span>{userData.pincode}</span>
            </p>
            <p>
              State : <span>{userData.state}</span>
            </p>
            <p>
              City : <span>{userData.city}</span>
            </p> */}
            <p>
              Address: <Link to="/sell/user/address" className="view__address">View your address</Link>
            </p>
          </div>
        </div>
      ) : null}

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default UserProfile;
