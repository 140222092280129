export const AboutWorkDetails = [
    {
      title: "Hyperlocal Platform",
      description:
        "A hyperlocal, technology-enabled, one-stop platform for the recycling industry",
    },
    {
      title: "Doorstep Pickup",
      description:
        "Schedule pickup at your convenience with zero hassles, with instant cash transfer to your account",
    },
    {
      title: "Trust & Commitment",
      description:
        "Electronic scales, competitive pre-determined prices, police verified vendors, hygiene & security",
    },
    {
      title: "Kabadiwala Support",
      description:
        "Providing back-end business processes support to local kabadiwalas",
    },
    {
      title: "Enhance Kabadiwala Income",
      description:
        "Creating growth in local businesses to increase their income, rather than take their jobs",
    },
    {
      title: "Innovation",
      description:
        "Heavy focus on research and development for constant innovation, with patented technologies",
    },
  ];
  