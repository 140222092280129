// image
import sell__item6 from "../../../../Image/sell__item6.PNG";

export const DealerRequestCategoryData = [
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
  {
    img: sell__item6,
    name: "Waste",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta, ipsum at posuere tempus, diam augue convallis lectus,",
    status: "Status",
  },
];
