import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

const Address = () => {

    const [address,setAddress] = useState([]);
    const [userProfile,setuserProfile] = useState();
    const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

    useEffect(() => {
        axios.get(`https://apis.kabaditechno.com/v3/api/view-profile/customer/${apiKey['id']}/`)
        .then((res) => {
            setuserProfile(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }
    )

useEffect(() => {
    axios.get(`https://apis.kabaditechno.com/address/get-address/${apiKey['id']}/`)
    .then((res) => {
        setAddress(res.data)
    }).catch((err)=> {
        console.log(err);
    })
},[])


  return (
    <>
    <div>Address Page</div>
    <button>Add</button>
    <button>Delete</button>
    <button>Update</button>
    <button>Get</button>
    <h3>Your addresses are:</h3>
    <p>${address.pincode}</p>
    </>
  )
}

export default Address