import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

// css
import "../../Css/SellItemCard.css";

// redux
import { useDispatch } from "react-redux";
import { cartReducerActions } from "../../Redux/cartReducer";
import axios from "axios";
import { apiUrl } from "../../Private";

const SellItemCard = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [cartData, setCartData] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  useEffect(() => {
    if (apiKey) {
      axios.get(`${apiUrl}/cart/viewCart/${apiKey['id']}/`)
        .then((res) => {
          setCartData(res.data)
        }).catch((err) => {
          console.log(err)
        })
    }
  }, [])


  // add to cart
  const addToCart = () => {

    if (apiKey) {
      const data = new FormData();
      data.append('customer', apiKey['id'])
      data.append('price_list', props.price_id)
      data.append('quantity', inputValue)
      if (inputValue !== "" && Number(inputValue) > 0) {
        // Cart is empty (only total amount etc returned in cartData)
        if (cartData.length === 1 || (props.dealer === cartData[0].dealer_id && props.pincode === cartData[0].pincode)) {
          axios.post(`${apiUrl}/cart/add_quantity/`, data, {
            headers: {
              "Content-type": "multipart/form-data",
            }
          }).then((res) => {
            // console.log('success')
          }).catch((err) => {
            console.log(err)
          })
          // ------------------------
          dispatch(
            cartReducerActions.add({
              item: {
                name: props.name,
                price: props.price,
                priceId: props.price_id,
                quantity: inputValue,
                totalPrice: Number(inputValue) * props.price,
              },
            })
          );
          Swal.fire({
            title: "Added to cart",
            confirmButtonColor: "#56b124",
          });
        } else {
          Swal.fire({
            title: "Dealer or Pincode didn't matched with your previous cart items. Place the order for previous cart items or clear your cart to proceed further",
            confirmButtonColor: "#56b124",
          });
        }
      }
      else {
        Swal.fire({
          title: "Add a quantity before add to cart",
          confirmButtonColor: "#56b124",
        });
      }
      setInputValue("");
    } else {
      Swal.fire({
        title: "Please Signin to add to cart",
        confirmButtonColor: "#56b124",
      });
      history.push("/signin");
    }
  };

  return (
    <div className="sell__item__card">
      <img src={`${apiUrl}/${props.img}`} alt="" />
      <div className="description">
        <h1>{props.name}</h1>
        <p>
          Price : <span>{props.price} Rs/Kg</span>
        </p>
        <input
          type="number"
          placeholder="Quantity in Kg"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <button onClick={addToCart}>Add to cart</button>
      </div>
    </div>
  );
};

export default SellItemCard;
