import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";

// css
import "../../Css/Cart.css";
import "../../App.css";

// component
import CartTable from "./CartTable";

// redux
import { useDispatch } from "react-redux";
import { stepReducerActions } from "../../Redux/stepReducer";
import { cartReducerActions } from "../../Redux/cartReducer";
import axios from "axios";
import { apiUrl } from "../../Private";
import Swal from "sweetalert2";

const CartStep3 = (props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [orderData,setOrderData] = useState({});
  const apiKey = JSON.parse(localStorage.getItem("KTMauth"))
  const order_id = localStorage.getItem("orderid")
  const order_number = localStorage.getItem("orderno")

  // get order details
  useEffect(() => {
    axios.get(`${apiUrl}/order/view-order-details/${apiKey['id']}/${order_id}/`)
    .then((res) => {
      console.log(res.data)
      const index = res.data.length - 1;
      setOrderData(res.data[index])
      console.log(res.data[index])
    }).catch((err) => {
      console.log(err)
    })
  },[])
  
  // getting cart items
  useEffect(() => {
    axios.get(`${apiUrl}/cart/viewCart/${apiKey['id']}/`)
      .then((res) => {
        setTableData(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const history = useHistory();

  // check out order initialization
  const checkOut = () =>{
    const data = new FormData();
    data.append("order", order_id )
    data.append("order_number", order_number )
    axios.post(`${apiUrl}/order/order-product-initialization/`,data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(() => {
      localStorage.removeItem("order_number")
      localStorage.removeItem("order_id")
      setIsOpen(!isOpen);
      // setTimeout(() => clearCart(), 10000);
    }).catch((err) => {
      console.log(err)
    })
  }

  // clearing cart on checkout
  const clearCart = () => {
    for (let i = 0; i < tableData.length - 1; i++) {
      const data = new FormData();
      data.append("customer", apiKey['id'])
      data.append("price_list", tableData[i]['price_list'])
      axios.post(`${apiUrl}/cart/remove_from_cart/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(() => {
        // setUpdated(!updated)
      }).catch(() => {
        Swal.fire({
          title: "Error in clearing cart",
          confirmButtonColor: "#56b124"
        })
      })
    }
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="cart__step">
        <h1>Final Checkout</h1>

        <CartTable />
        
        <div className="final__pickup__address">
          <h1>Pickup Address</h1>
          <div className="pickup__address">
            <div className="left__side">
              <p>
                Name : <span>{orderData.full_name}</span>
              </p>
              <p>
                Mobile Number : <span>{orderData.phone}</span>
              </p>
              <p>
                Email ID : <span>{orderData.email}</span>
              </p>
              <p>
                Address : <span>{orderData.full_address}</span>
              </p>
              <p>
                State : <span>{orderData.state}</span>
              </p>
            </div>
            <div className="right__side">
              <p>
                City : <span>{orderData.city}</span>
              </p>
              <p>
                House no. / Flat no. : <span>147A</span>
              </p>
              <p>
                Date : <span>{orderData.pickup_date}</span>
              </p>
              <p>
                Time Slot : <span>{orderData.pickup_time}</span>
              </p>
            </div>
          </div>
        </div>

        <button
          className="cart__button"
          onClick={() => {
            dispatch(stepReducerActions.backward("cartStep"));
          }}
        >
          Back
        </button>
        <button
          className="cart__button"
          onClick={checkOut}
        >
          Check out
        </button>

        <Modal
          className="modal__content"
          overlayClassName="modal__overlay"
          isOpen={isOpen}
          ariaHideApp={false}
        >
          <h1>Thanks</h1>
          <p>Your pickup request is placed</p>
          <div>
            <button
              onClick={() => {
                dispatch(cartReducerActions.reset());
                dispatch(stepReducerActions.reset("cartStep"));
                setIsOpen(!isOpen);
                history.push("/sell");
              }}
            >
              Sell again
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CartStep3;
