import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

// css
import "../../../Css/UserDealerProfileEdit.css";
import "../../../App.css";

// image
import customer__profile__img from "../../../Image/customer__profile__img.PNG";

// component
import DealerProfileSearchbar from "../DealerProfileSearchbar";
import DealerProfileNavbar from "../DealerProfileNavbar";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// api url
import { apiUrl } from "../../../Private";
import Swal from "sweetalert2";

const DealerProfileEdit = () => {
  const [dealerData, setDealerData] = useState();

  const [uploadedImage, setUploadedImage] = useState({
    photo: customer__profile__img,
  });


  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  const history = useHistory();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get dealer data
  useEffect(() => {
    axios.get(`${apiUrl}/v3/api/view-profile/dealer/${apiKey['id']}/`)
      .then((response) => {
        setDealerData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // get input value
  const getInputValue = (e) => {
    setDealerData({ ...dealerData, [e.target.name]: e.target.value });
  };

  // update dealer data
  const updateDealerProfile = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("Did", apiKey['id']);
      data.append("mobile_number", dealerData.mobile_number);
      data.append("account_type", apiKey['account_type']);

      fetch(`${apiUrl}/v3/api/update-attrs/dealer/`, {
        method: "POST",
        body: data,
        "Content-type": "multipart/form-data",
      });

      Swal.fire({
        title: "Profile Updated",
        confirmButtonColor: "#56b124"
      })
      history.push("/dealer/profile");
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Profile not updated",
        confirmButtonColor: "#56b124"
      })
    }
  };

  // upload Profile Pic
  const updatePhoto = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUploadedImage({ ...uploadedImage, [e.target.name]: reader.result });
      }
    };
    const data = new FormData();
    data.append("ProfilePic", e.target.files[0]);

    fetch(`${apiUrl}/v3/api/update-profilepic/dealer/${apiKey['id']}/`, {
      method: "POST",
      body: data,
      "Content-type": "multipart/form-data",
    });

    Swal.fire({
      title: "Profile Pic changed successfully",
      confirmButtonColor: "#56b124"
    })
  };


  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      {dealerData !== undefined ? (
        <div className="user__dealer__profile__edit similar__section">
          <h1 className="similar__section__heading">Edit your profile</h1>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="photo"
            name="photo"
            onChange={updatePhoto}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <label htmlFor="photo" className="change__pic__label">Select Profile Pic</label>
          <form onSubmit={updateDealerProfile}>
            <input
              type="text"
              placeholder="Name"
              value={dealerData.name}
              name="name"
              onChange={getInputValue}
            />
            <input
              type="email"
              placeholder="Email ID"
              disabled
              value={dealerData.email}
            />
            <input
              type="tel"
              placeholder="Mobile Number"
              value={dealerData.mobile}
              name="mobile"
              onChange={getInputValue}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      ) : null}

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerProfileEdit;
