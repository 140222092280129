import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

// component
import Navbar from "../../Navbar";
import UserProfileSearchbar from "../../UserProfileSearchbar";
import UserProfileNavbar from "../UserProfileNavbar";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// css
import "../../../Css/UserDealerProfileEdit.css";
import "../../../App.css";

// image
import customer__profile__img from "../../../Image/customer__profile__img.PNG";

// api url
import { apiUrl } from "../../../Private";

const UserProfileEdit = () => {
  const [userData, setUserData] = useState({ "ProfilePic": customer__profile__img });

  const [uploadedImage, setUploadedImage] = useState({
    photo: userData.ProfilePic,
  });

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  const history = useHistory();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get user data
  useEffect(() => {
    axios
      .get(`${apiUrl}/v3/api/view-profile/customer/${apiKey['id']}/`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // get input value
  const getInputValue = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // update user data
  const updateUserProfile = (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("Cid", apiKey['id']);
      data.append("mobile_number", userData.mobile_number);
      data.append("account_type", apiKey['account_type']);
      data.append("account_category", apiKey['account_category']);

      fetch(`${apiUrl}/v3/api/update-attrs/customer/`, {
        method: "POST",
        body: data,
        "Content-type": "multipart/form-data",
      });

      Swal.fire({
        title: "Profile Updated",
        confirmButtonColor: "#56b124"
      })
      history.push("/sell/user/profile");
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Profile not updated",
        confirmButtonColor: "#56b124"
      })
    }
  };

  // upload Profile Pic
  const updatePhoto = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUploadedImage({ ...uploadedImage, [e.target.name]: reader.result });
      }
    };
    const data = new FormData();
    data.append("ProfilePic", e.target.files[0]);

    fetch(`${apiUrl}/v3/api/update-profilepic/customer/${apiKey['id']}/`, {
      method: "POST",
      body: data,
      "Content-type": "multipart/form-data",
    });

    Swal.fire({
      title: "Profile Pic changed successfully",
      confirmButtonColor: "#56b124"
    })
  };


  return (
    <>
      <Navbar />

      <UserProfileSearchbar />

      <UserProfileNavbar />

      {userData !== undefined ? (
        <div className="user__dealer__profile__edit similar__section">
          <h1 className="similar__section__heading">Edit your profile</h1>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="photo"
            name="photo"
            onChange={updatePhoto}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <label htmlFor="photo" className="change__pic__label">Select Profile Pic</label>
          <form onSubmit={updateUserProfile}>
            <input
              type="text"
              placeholder="Name"
              value={userData.username}
              name="username"
              onChange={getInputValue}
              disabled
            />
            <input
              type="email"
              placeholder="Email ID"
              disabled
              value={userData.email}
            />
            <input
              type="tel"
              placeholder="Mobile Number"
              value={userData.mobile_number}
              name="mobile_number"
              onChange={getInputValue}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      ) : null}

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default UserProfileEdit;
