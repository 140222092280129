import axios from "axios";
import React from "react";
import Swal from "sweetalert2";

// css
import "../../../Css/UserDealerPickupCard.css";

// image
import pickup__truck from "../../../Image/pick-up-truck.png";
import { apiUrl } from "../../../Private";

const UserDealerPickupCard = (props) => {

  const cancelPickup = () => {
    axios.get(`${apiUrl}/order/cancel-order-via-dealer/${props.uniqueId}/${props.order_no}/`)
    .then((res) => {
      console.log(res)
      Swal.fire({
        title: "Order Canceled",
        confirmButtonColor: "#56b124"
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  const confirmPickup = () => {
    axios.get(`${apiUrl}/order/accepted-order-via-dealer/${props.uniqueId}/${props.order_no}/`)
    .then((res) => {
      Swal.fire({
        title: "Order Accepted",
        confirmButtonColor: "#56b124"
      })
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className="user__dealer__pickup__card">
      <div className="left__side">
        <img src={pickup__truck} alt="" />
      </div>
      <div className="right__side">
        <p>
          Unique ID : <span>{props.uniqueId}</span>
        </p>
        {/* <p>
          On : <span>{props.date}</span>
        </p>
        <p>
          At : <span>{props.time}</span>
        </p> */}
        <p>
          Status : <span>{props.status}</span>
        </p>
        <div className="pickup__btns">
        <button onClick={cancelPickup}>Cancel</button>
        <button onClick={confirmPickup}>confirm</button>
        </div>
      </div>
    </div>
  );
};

export default UserDealerPickupCard;
