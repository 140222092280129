import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { apiUrl } from "../../../../Private";

import DeleteIcon from '@mui/icons-material/Delete';

// css
import "../../../../Css/DealerPriceItemCard.css";
import { useHistory } from "react-router-dom";

const DealerEditPriceCard = (props) => {
  // set price

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  const history = useHistory();

  const [pricePincode, setPricePincode] = useState([]);
  const [pincodes, setPincodes] = useState([]);

  const image_url = `${apiUrl}/${props.img}`;

  const [inputValue, setInputValue] = useState([
    {
      pincode: "",
      subcategory: props.subcategory,
      dealer: apiKey['id'],
      price: "0"
    },
    {
      pincode: "",
      subcategory: props.subcategory,
      dealer: apiKey['id'],
      price: "0"
    },
    {
      pincode: "",
      subcategory: props.subcategory,
      dealer: apiKey['id'],
      price: "0"
    },
    {
      pincode: "",
      subcategory: props.subcategory,
      dealer: apiKey['id'],
      price: "0"
    },
    {
      pincode: "",
      subcategory: props.subcategory,
      dealer: apiKey['id'],
      price: "0"
    },
  ]);

  useEffect(() => {
    pincodes.map((eachPin,eachPinIndex) => {
      pricePincode.map((eachDetail,eachDetailIndex) => {
        if ((eachDetail['subcategory_name'] === props.name) && (eachDetail.pincode === eachPin)) {
          const tempData = [...inputValue]
          tempData[eachPinIndex]['price']  = eachDetail.price
          tempData[eachPinIndex]['pincode']  = eachDetail.pincode
        setInputValue(tempData)
      }
      })
    })
  },[pricePincode,pincodes])

  // get price and pincode
  useEffect(() => {
    axios.get(`${apiUrl}/dealer_details/get-price/${apiKey['id']}/`)
      .then((res) => {
        setPricePincode(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // get all pincodes of dealer
  useEffect(() => {
    axios.get(`${apiUrl}/dealer_details/get_all_pincodes/${apiKey['id']}/`)
      .then((res) => {
        const pins = res.data;
        let pinsfinal = []
        for(let i=0;i<5;i++)
        {
          if(pins[`Pincode ${i+1}`] !== undefined)
          pinsfinal[i] = pins[`Pincode ${i+1}`]
          else pinsfinal[i] = 0
        }
        // const pinsfinal = [pins['Pincode 1'], pins['Pincode 2'], pins['Pincode 3'], pins['Pincode 4'], pins['Pincode 5']]
        setPincodes(pinsfinal)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const getInputValue = (index, eachPin, e) => {
    let data = [...inputValue]
    data[index][e.target.name] = e.target.value
    data[index]['pincode'] = eachPin
  }

  // delete the price
  const deletePrice = (index, pincode) => {

    let inputs = [...inputValue]

    const data = new FormData();
    data.append("dealer", apiKey['id'])
    data.append("subcategory", inputs[index]['subcategory'])
    data.append("pincode", pincode)

    axios.post(`${apiUrl}/dealer_details/delete-price/`, data, {
      headers: {
        "Content-type": "multipart/form-data",
      }
    })
      .then((res) => {
        Swal.fire({
          title: "successfully removed",
        })
      })
      .catch((err) => {
        console.log(err)
        Swal.fire({
          title: "successfully removed",
          confirmButtonColor: "green",
        })
      })
  }

  const setPrice = async (e) => {
    e.preventDefault();

    // const data = new FormData();
    // data.append("subcategory", props.subcategory);
    // data.append("dealer", apiKey['id']);
    // data.append("pincode", "787555");
    // data.append("price", 14);

    // setInputValue({ image: upload__document, name: "", description: "" });
    inputValue.forEach(element => {

      // we can directly pass element also but price ka datatype string hone ki vajah se error aayegi isliye form-data create krna pada

      const data = new FormData();
      data.append("dealer",element.dealer)
      data.append("pincode",element.pincode)
      data.append("price",element.price)
      data.append("subcategory",element.subcategory)
      axios.post(`${apiUrl}/dealer_details/add-price/`, data, {
        headers: {
          "Content-type": "multipart/form-data",
        }
      }).then(() => {
        Swal.fire({
          title: "Successfully added",
          confirmButtonColor: "#56b124",
        });
      }).catch((err) => {
        console.log(err)
        axios.post(`${apiUrl}/dealer_details/update-price/`, element, {
          headers: {
            "Content-type": "multipart/form-data",
          }
        }).then(() => {
          Swal.fire({
            title: "Successfully updated",
            confirmButtonColor: "#56b124",
          });
          history.push('/dealer/settings/setprice/')
        }).catch((err) => {
          console.log(err)
        })
      })


    });
    // for (var i = 0; i < 5; i++)
    // {
    //   console.log(inputValue)
    // console.log(inputValue[i])
    // setInputValue(
    //   inputValue[i].pincode = "",
    //   inputValue[i].price = "",
    // )
    // }

    Swal.fire({
      title: "The price has been set successfully",
      confirmButtonColor: "#56b124",
    });
  };

  return (
    <form className="dealer__price__item__card" onSubmit={setPrice}>
      <div className="details__section">
        <div className="img">
          <img src={image_url} alt="" />
        </div>
        <div className="details">
          <h1>{props.name[0].toUpperCase() + props.name.slice(1)}</h1>
          <p>Set area price</p>
          <div className="check__all">
            <input type="checkbox" />
            <p>Set same price for all area pincode</p>
          </div>
          {pincodes.map((eachPin, eachPinIndex) => {
            let f = 0;
            if(eachPin > 0)
            return (
              <div className="price__section">
                <h1>{eachPin}</h1>
                {/* <input value={eachPin} name="pincode" readOnly={true} onChange={getPin} /> */}
                <p>:</p>
                {
                  pricePincode.map((eachDetail, eachDetailIndex) => {
                    // const dataVal = [...inputValue]
                    // console.log(dataVal)
                    // dataVal[eachPinIndex]['price'] = eachDetail.price
                    // dataVal[eachPinIndex]['pincode'] = eachDetail.pincode
                    // setInputValue(dataVal)

                    if ((eachDetail['subcategory_name'] === props.name) && (eachDetail.pincode === eachPin)) {
                      f = 1;
                      return (
                        <>
                          {/* {eachPin === "undefined" && <h1>0</h1>} */}
                          <input
                            type="text"
                            placeholder={eachDetail.price}
                            onChange={(e) => { getInputValue(eachPinIndex, eachPin, e) }}
                            name="price"
                            value={inputValue.price}
                          />
                        </>
                      );
                    }
                  })
                }
                {!f && <input
                  type="text"
                  onChange={(e) => { getInputValue(eachPinIndex, eachPin, e) }}
                  name="price"
                  value={inputValue.price}
                />
                }
                {f ? <DeleteIcon onClick={() => { deletePrice(eachPinIndex, eachPin) }} /> : null}
              </div>
            );
          })}
        </div>
      </div>
      <div className="action__section">
        <div className="agree">
          <input type="checkbox" required />
          <p>Kabadi Techno Commission 1% Per/kg</p>
        </div>
        <button type="submit">Set Price</button>
      </div>
    </form>
  );
};

export default DealerEditPriceCard;
