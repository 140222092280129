import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

// css
import "../Css/UserProfileSearchbar.css";

import { apiUrl } from "../Private";

// material icon
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';


const UserProfileSearchbar = () => {

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  const gAuth = JSON.parse(localStorage.getItem("KTMgauth"));
  const [cartData, setCartData] = useState([])

  // get number of products badge
  useEffect(() => {
    axios.get(`${apiUrl}/cart/viewCart/${apiKey['id']}/`)
      .then((res) => {
        setCartData(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  // get user name
  // useEffect(() => {
  //   if (apiKey !== null) {
  //     const profileUrl = `${apiUrl}/v3/api/database-checker/customer/${apiKey["email"]}/`;
  //     console.log(profileUrl);
  //     // commented due to warning
  //     // const config = {
  //     //   headers: {
  //     //     Authorization: `Token ${apiKey["key"]}`,
  //     //   },
  //     // };

  //     axios
  //       .get(profileUrl)
  //       // .get(profileUrl, config)
  //       .then((response) => {
  //         // setUserName(response.data);
  //         console.log(response);
  //         setUserName(apiKey["username"]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [apiKey]);       //If any error..remove this apiKey

  return (
    <div className="user__profile__searchbar">
      <div className="searchbar">
        <input type="text" />
        <SearchIcon />
      </div>
      <div className="profile">
        <p>
          {apiKey['username'] !== undefined
            // ? `Welcome ${userName.name}`
            ? `Welcome ${apiKey['username']}`
            : gAuth !== null
              ? "Welcome As Guest"
              : "Loading..."}
        </p>
        <div>
          <NavLink to="/sell/user/profile" className="profile__icon">
            <AccountCircleIcon />
          </NavLink>
          <NavLink to="/sell/cart" className="cart__icon">
            {
              cartData.length ?
            <Badge badgeContent={cartData.length - 1} color="primary">
              <ShoppingCartIcon />
            </Badge> : 
            <Badge badgeContent={cartData.length} color="primary">
            <ShoppingCartIcon />
          </Badge>
            }

          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSearchbar;
