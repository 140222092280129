import React from "react";

// css
import "../../Css/DealerProfileSearchbar.css";

// material icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';

// logo
import kabadi__techno__logo from "../../Image/kabadi__techno__logo.png";

const DealerProfileSearchbar = () => {

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  const dealerName = apiKey['username']
  // const [dealerName, setDealerName] = useState();

  // get dealer name
  // useEffect(() => {
  //   const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  //   const profileUrl = `${apiUrl}/users/profile/`;
  //   const config = {
  //     headers: {
  //       Authorization: `Token ${apiKey["key"]}`,
  //     },
  //   };

  //   axios
  //     .get(profileUrl, config)
  //     .then((response) => {
  //       setDealerName(response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <div className="dealer__profile__searchbar">
      <span className="logo">
        <img src={kabadi__techno__logo} alt="" />
      </span>
      <div className="searchbar">
        <input type="text" />
        <SearchIcon />
      </div>
      <p>
        Welcome {dealerName !== undefined ? dealerName : null}
        <AccountCircleIcon />
      </p>
    </div>
  );
};

export default DealerProfileSearchbar;
