import React, { useState, useEffect } from "react";

// css
import "../../../Css/DealerHome.css";
import "../../../App.css";

// component
import DealerProfileSearchbar from "../DealerProfileSearchbar";
import DealerProfileNavbar from "../DealerProfileNavbar";
import DealerHomeCard from "./DealerHomeCard";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";
import axios from "axios";
import { apiUrl } from "../../../Private";

// api url
// import { apiUrl } from "../../../Private";

const DealerHome = () => {
  const [pickupData,setPickupData] = useState([]);
  const [homeData, setHomeData] = useState([
    { title: "Total Pickup", number: "0" },
    { title: "Total Category", number: "0" },
    { title: "Total Successful", number: "0" },
    { title: "Total Cancel", number: "0" },
    { title: "Total Today Pickup", number: "0" },
  ]);

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  
  // get pickup data
  useEffect(() => {
    axios.get(`${apiUrl}/order/get-all-orders-dealer/${apiKey['id']}/`)
      .then((res) => {
        setPickupData(res.data);
        const updatePickups = homeData.map(eachTitle => {
          if(eachTitle.title === "Total Pickup")
          return {
            ...homeData,
            title: "Total Pickup",
            number: res.data.length,
          };
          else return eachTitle;
        })
        setHomeData(updatePickups)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(pickupData)
  console.log(pickupData.length)

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      <div className="dealer__home similar__section">
        <h1 className="similar__section__heading">Dashboard</h1>

        <div className="dashboard">
          {homeData.map((eachData, eachDataIndex) => {
            return (
              <DealerHomeCard
                key={eachDataIndex}
                title={eachData.title}
                number={eachData.number}
              />
            );
          })}
        </div>
      </div>

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerHome;
