import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const cartReducer = createSlice({
  name: "cart reducer",
  initialState,
  reducers: {
    add: (state, action) => {
      state.push(action.payload.item)
      // return [...state, action.payload.item];
    },
    remove: (state, action) => {
      // const newState = state.filter((eachItem, eachItemIndex) => {
      //   return eachItemIndex !== action.payload;
      // });
      // return newState;
      // state.pop(action.payload)
      state.splice(action.payload,1)
    },
    reset: () => {
      // not correct because here new empty array is assigned
      // return state = [];
      return [];
    },
  },
});

export const cartReducerActions = cartReducer.actions;
export default cartReducer.reducer;
