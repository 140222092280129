import React from "react";

// css
import "../../Css/Footer.css";

const TermFooter = () => {
  return (
    <div className="term__footer">
      All rights reserved by Kabadi Techno Private Limited
    </div>
  );
};

export default TermFooter;
