import React, { useEffect, useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// css
import "../../Css/Cart.css";
import "../../Css/Auth.css";

// redux
import { useDispatch } from "react-redux";
import { stepReducerActions } from "../../Redux/stepReducer";
import axios from "axios";
import { apiUrl } from "../../Private";
import Swal from "sweetalert2";

const CartStep2 = () => {
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState([]);
  const apiKey = JSON.parse(localStorage.getItem("KTMauth"))
  const pincode = JSON.parse(localStorage.getItem("KTMpincode"))
  const [inputValue, setInputValue] = useState({
    firstname: apiKey['username'],
    lastname: "",
    phone: "",
    email: apiKey['email'],
    add_one: "",
    add_two: "",
    city: "",
    state: "",
    country: "India",
    pickup_date: "",
    pickup_time: ""
  });


  // get address of user
  useEffect(() => {
    axios.get(`${apiUrl}/address/get-address-by-ktid/${apiKey['KT_ID']}/`)
      .then((res) => {
        console.log(res.data);
        setUserAddress(res.data);
      }).catch((err) => {
        console.log(err);
      })
  }, []);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // getInput Value
  const getInputValue = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    })
  }

  const useAddress = (inputs) => {
    console.log(inputs)
    setInputValue({
      ...inputValue,
      add_one: inputs.address,
      add_two: inputs.address_area,
      phone: inputs.phone_number,
      city: inputs.city,
      state: inputs.state,
    })
  }

  const order = () => {

    // Form validations
    if (
      typeof inputValue.firstname === "string" &&
      typeof inputValue.lastname === "string" &&
      inputValue.firstname !== "" &&      
      inputValue.lastname !== "" && 
      !isNaN(inputValue.phone) &&
      inputValue.phone.length === 10 &&
      inputValue.add_one !== "" &&
      inputValue.city !== "" &&
      inputValue.state !== "" &&
      inputValue.country !== "" &&
      inputValue.pickup_date !== ""
    ) {
      const data = new FormData();
      data.append("customer_id", apiKey['id']);
      data.append("first_name", inputValue.firstname);
      data.append("last_name", inputValue.lastname);
      data.append("email", apiKey['email']);
      data.append("phone", inputValue.phone);
      data.append("address_line_1", inputValue.add_one);
      data.append("address_line_2", inputValue.add_two);
      data.append("city", inputValue.city);
      data.append("state", inputValue.state);
      data.append("country", inputValue.country);
      data.append("pickup_date", inputValue.pickup_date);
      data.append("pickup_time", inputValue.pickup_time);

      axios.post(`${apiUrl}/order/take-order-details/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((res) => {
        console.log(res.data)
        localStorage.setItem("orderno", res.data.order_number)
        localStorage.setItem("orderid", res.data.id)
        dispatch(stepReducerActions.forward("cartStep"));
      }).catch((err) => {
        console.log(err)
      })
    }
    else {
      Swal.fire({
        title: "Please fill the form properly",
        confirmButtonColor: "#56b124"
      })
    }
  }

  return (
    <>
      <div className="cart__step">
        <h1>Your Pickup Address</h1>
        <div className="cart__pickup__input">
          {useAddress.length !== 0 ? (
            <div className="cart__pickup__carousel__section">
              <Splide
                className="cart__pickup__addresses"
                options={{
                  type: "slide",
                  gap: "1rem",
                  autoplay: true,
                  pauseOnHover: false,
                  resetProgress: false,
                  pagination: false,
                  arrows: true,
                  perMove: 1,
                  direction: 'ltr',
                  breakpoints: {
                    623: {
                      perPage: 1,
                    },
                    935: {
                      perPage: 2,
                    },
                    1247: {
                      perPage: 3,
                    }
                  },
                  perPage: 3
                }}
              >
                {userAddress.map((eachMember, eachMemberIndex) => {
                  if(eachMember.pincode === pincode)
                  return (
                    <SplideSlide className="each__address" key={eachMemberIndex}>
                      <div onClick={useAddress.bind(this, eachMember)}>
                        <p><span>Address: </span> {eachMember.address}</p>
                        <p><span>City: </span> {eachMember.city}</p>
                        <p><span>State: </span> {eachMember.state}</p>
                        <p><span>Pincode: </span> {eachMember.pincode}</p>
                        <p><span>Phone No: </span> {eachMember.phone_number}</p>
                        <p><span>Default Address: </span> {eachMember.default === "not default" && "NO"}{eachMember.default === "default" && "YES"}</p>
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          ) : null}

          {/* AddressCards  */}

          {/* {userAddress.length !== 0 ? (
            userAddress.map((eachData, eachDataIndex) => {
              return (
                <>
                  <AddressCards
                    key={eachDataIndex}
                    pincode={eachData.pincode}
                    address_id={eachData.id}
                    state={eachData.state}
                    city={eachData.city}
                    area={eachData.address}
                    address_area={eachData.address_area}
                    landmark={eachData.landmark}
                    useAddress={useAddress.bind(this, eachData)}
                  />
                </>
              );
            })
          ) : (
            <div>
              <button>Add address</button>
            </div>
          )
          }

          <Link to="/sell/user/address">
            <button className="addresscard__addaddress">Use another address</button>
          </Link> */}

          {/* end of AddressCards  */}

          <div className="input">
            <div>
              <label>First Name*</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.firstname}
                name="firstname"
                required
              />
            </div>
            <div>
              <label>Last Name*</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.lastname}
                name="lastname"
                required
              />
            </div>
          </div>
          <div className="input">
            <div>
              <label>Mobile Number*</label>
              <input type="tel"
                onChange={getInputValue}
                value={inputValue.phone}
                name="phone"
                required
              />
            </div>
            <div>
              <label>Email ID</label>
              <input type="email"
                onChange={getInputValue}
                value={inputValue.email}
                name="email"
                required
                disabled />
            </div>
          </div>
          <div className="input">
            <div>
              <label>City*</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.city}
                name="city"
                required
              />
            </div>
            <div>
              <label>State*</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.state}
                // value={optionValue}
                name="state"
                required />
            </div>
          </div>
          <div className="input">
            <div>
              <label>House no. / Flat no. *</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.add_one}
                name="add_one"
                required
              />
            </div>
            <div>
              <label>Address*</label>
              <input type="text"
                onChange={getInputValue}
                value={inputValue.add_two}
                name="add_two"
                required
              />
            </div>
          </div>
          <div className="input">
            <div>
              <label>Select Date*</label>
              <input type="date"
                onChange={getInputValue}
                value={inputValue.pickup_date}
                name="pickup_date"
                required
              />
            </div>
            <div>
              <label>Select Time Slot</label>
              <select
                onChange={getInputValue}
                value={inputValue.pickup_time}
                name="pickup_time"
                required
              >
                <option>8am - 1pm</option>
                <option>1pm - 6pm</option>
              </select>
            </div>
          </div>
        </div>

        <button
          className="cart__button"
          onClick={() => {
            dispatch(stepReducerActions.backward("cartStep"));
          }}
        >
          Back
        </button>
        <button
          className="cart__button"
          onClick={order}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default CartStep2;
