import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { apiUrl } from "../../Private";

// css
import "../../Css/DealerContactCard.css";
import Swal from "sweetalert2";
//material ui
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const DealerContactCard = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [sellInput, setSellInput] = useState({
    dealer_id: "",
    customer_name: "",
    phone: "",
    email: "",
    itemName: "",
    itemPic: "",
    quantity: "",
    description: "",
  })

  const getSellInput = (e) => {
    console.log(sellInput)
    setSellInput({
      ...sellInput,
      [e.target.name]: e.target.value,
    })
  }

  const uploadPic = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSellInput({
          ...sellInput,
          [e.target.name]: reader.result,
        })
      }
    };
  }

  const openModal = () => {
    setIsOpen(!isOpen);
  }

  const submitSellItem = async () => {

    if (
      typeof sellInput.customer_name === "string" &&
      !isNaN(sellInput.phone) &&
      sellInput.phone.length === 10 &&
      sellInput.quantity > 0
    ) {
      const data = new FormData();
      data.append("dealer_id", props.dealerId);
      data.append("customer_name", sellInput.customer_name);
      data.append("phone", sellInput.phone);
      data.append("email", sellInput.email);
      data.append("itemName", sellInput.itemName);
      // data.append("itemPic", sellInput.itemPic);
      data.append("quantity", sellInput.quantity);
      data.append("description", sellInput.description);
      if (sellInput.itemPic !== "")
        data.append("itemPic", sellInput.itemPic);

      axios.post(`${apiUrl}/dealer/api/requestinquiry-post/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(() => {
        setIsOpen(!isOpen);
        setSellInput({
          dealer_id: "",
          customer_name: "",
          phone: "",
          email: "",
          itemName: "",
          itemPic: "",
          quantity: "",
          description: "",
        })
        Swal.fire({
          title: "Order Placed",
          confirmButtonColor: "#56b124"
        })
      }).catch((err) => {
        Swal.fire({
          title: "Order can't be placed",
          confirmButtonColor: "#56b124"
        })
      })
    }
    else 
    Swal.fire({
      title: "Fill the form properly",
          confirmButtonColor: "#56b124"
    })
  }

  return (
    <>
      <div className="dealer__contact__card">
        <h2>KTD{props.dealerId} is available</h2>
        <p>
          Dealing in :{" "}
          {props.Dealing.map((item, index) => {
            return <span key={index}>{item}, </span>;
          })}
        </p>
        <p>
          Minimum Qty : <span>{props.Minimum}kg</span>
        </p>
        <p>
          Maximum Qty : <span>{props.Maximum}kg</span>
        </p>
        <p>
          Timing : <span>8am to 7pm</span>
        </p>
        <button onClick={() => { setIsOpen(!isOpen) }}>Sell</button>
      </div>
      <Modal
        className="modal__content"
        overlayClassName="modal__overlay"
        isOpen={isOpen}
        ariaHideApp={false}
      >
        <CloseRoundedIcon fontSize="large" className="modal__close__icon" onClick={openModal}/>
        <h1>Fill the below form to sell your item</h1>
        <input
          type="file"
          required
          accept="image/*"
          style={{ display: "none" }}
          name="itemPic"
          id="itemPic"
          onChange={(e) => { uploadPic(e) }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <label htmlFor="itemPic">Select Item Pic</label>
        <input
          type="text"
          value={sellInput.customer_name}
          name="customer_name"
          placeholder="Customer Name"
          onChange={(e) => { getSellInput(e) }}
        />
        <input
          type="text"
          value={sellInput.phone}
          name="phone"
          placeholder="Phone number"
          onChange={(e) => { getSellInput(e) }}
        />
        <input
          type="email"
          value={sellInput.email}
          name="email"
          placeholder="Email"
          onChange={(e) => { getSellInput(e) }}
        />
        <input
          type="text"
          value={sellInput.itemName}
          name="itemName"
          placeholder="Item Name"
          onChange={(e) => { getSellInput(e) }}
        />
        <input
          type="number"
          value={sellInput.quantity}
          name="quantity"
          placeholder="Quantity"
          onChange={(e) => { getSellInput(e) }}
        />
        <input
          type="text"
          value={sellInput.description}
          name="description"
          placeholder="Description"
          onChange={(e) => { getSellInput(e) }}
        />
        <div>
          <button onClick={submitSellItem}>Submit</button>
        </div>
      </Modal>
    </>
  );
};

export default DealerContactCard;
