// image
import edit__price__item__img from "../../../../Image/edit__price__item__img.png";

export const DealerPriceListItemData = [
  {
    name: "paper",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
  {
    name: "glass",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
  {
    name: "plastic",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
  {
    name: "metal",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
  {
    name: "e-waste",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
  {
    name: "other",
    img: edit__price__item__img,
    pincodeDetails: [
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
      { pincode: "732102", price: "12", priceIn: "Per/Kg" },
    ],
  },
];
