import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

// css
import "../../Css/ProfileNavbar.css";

// material icon
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CropFreeIcon from "@mui/icons-material/CropFree";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// redux
import { useDispatch } from "react-redux";
import { cartReducerActions } from "../../Redux/cartReducer";
import { stepReducerActions } from "../../Redux/stepReducer";
import { apiUrl } from "../../Private";
import axios from "axios";

const UserProfileNavbar = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  // log out
  const logOut = () => {

    const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

    axios.post(`${apiUrl}/v3/api/logout/customer/${apiKey['tokens']['refresh']}`, null, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${apiKey['tokens']['refresh']}`
      }
    })
      .then((res) => {
        console.log(res.data)

        dispatch(cartReducerActions.reset());
        dispatch(stepReducerActions.reset("cartStep"));
        localStorage.removeItem("KTMauth");
        localStorage.removeItem("KTMpincode");
        localStorage.removeItem("KTMsellItemName");
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="menubar"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      <ul
        className={
          isOpen ? "profile__navbar profile__navbar__active" : "profile__navbar"
        }
      >
        <li>
          <NavLink
            to="/sell/user/profile"
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <AccountCircleIcon /> Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sell/user/pickup"
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <TransferWithinAStationIcon /> Pickup
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sell/user/wallet"
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <AccountBalanceWalletIcon /> Wallet
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sell/user/qr"
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <CropFreeIcon /> QR Code
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sell/user/autoscrap"
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <AutorenewIcon /> Auto Scrap
          </NavLink>
        </li>
        <li>
          <button
            className="profile__link"
            activeClassName="active__profile__link"
            onClick={logOut}
          >
            <ExitToAppIcon /> Logout
          </button>
        </li>
      </ul>
    </>
  );
};

export default UserProfileNavbar;
