import React, { useState, useEffect } from "react";
import axios from "axios";

// component
import DealerProfileSearchbar from "../DealerProfileSearchbar";
import DealerProfileNavbar from "../DealerProfileNavbar";
import DealerPickupCard from "./DealerPickupCard";
import MainFooter from "../../Footer/MainFooter";
import TermFooter from "../../Footer/TermFooter";

// css
import "../../../Css/UserDealerPickup.css";
import "../../../App.css";

// api url
import { apiUrl } from "../../../Private";

const DealerPickup = () => {
  const [pickupData, setPickupData] = useState([]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apiKey = JSON.parse(localStorage.getItem("KTMauth"));

  // get pickup data
  useEffect(() => {
    axios.get(`${apiUrl}/order/get-all-orders-dealer/${apiKey['id']}/`)
      .then((res) => {
        console.log(res.data)
        setPickupData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <DealerProfileSearchbar />

      <DealerProfileNavbar />

      <div className="user__dealer__pickup__section similar__section">
        <h1 className="similar__section__heading">Your Pickup</h1>

        <div className="user__dealer__pickup">
          {pickupData.length !== 0 ? (
            pickupData.map((eachData) => {
              return (
                <DealerPickupCard
                  key={eachData.id}
                  uniqueId={eachData.id}
                  // date={eachData.pickup_date}
                  // time={eachData.pickup_time}
                  status={eachData.status}
                  order_no={eachData.order_number}
                />
              );
            })
          ) : (
            <p>No pickup data available here</p>
          )}
        </div>
      </div>

      <MainFooter />

      <TermFooter />
    </>
  );
};

export default DealerPickup;
