import React, { useEffect, useState } from "react";
import axios from "axios";

// css
import "../../Css/Cart.css";

// material icon
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

// redux
import { useSelector, useDispatch } from "react-redux";
import { cartReducerActions } from "../../Redux/cartReducer";

// apiUrl
import { apiUrl } from "../../Private";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { stepReducerActions } from "../../Redux/stepReducer";

const CartTable = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartReducer);
  const cartStep = useSelector((state) => state.stepReducer.cartStep);
  const history = useHistory();
  
  console.log(cart)

  let total = 0;

  const apiKey = JSON.parse(localStorage.getItem('KTMauth'))

  const [tableData, setTableData] = useState([]);
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    axios.get(`${apiUrl}/cart/viewCart/${apiKey['id']}/`)
      .then((res) => {
        setTableData(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [updated])

  const decrement = (e, price_list) => {
    const data = new FormData();
    data.append("customer", apiKey['id'])
    data.append("price_list", price_list)
    axios.post(`${apiUrl}/cart/decrease_quantity/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(() => {
      setUpdated(!updated)
      Swal.fire({
        title: "Quantity decreased by 1",
        confirmButtonColor: "#56b124"
      })
    }).catch(() => {
      Swal.fire({
        title: "Error in Decreasing quantity",
        confirmButtonColor: "#56b124"
      })
    })
  }

  const increment = (e, price_list) => {
    const data = new FormData();
    data.append("customer", apiKey['id'])
    data.append("price_list", price_list)
    axios.post(`${apiUrl}/cart/add_to_cart/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(() => {
      setUpdated(!updated)
      Swal.fire({
        title: "Quantity increased by 1",
        confirmButtonColor: "#56b124"
      })
    }).catch(() => {
      Swal.fire({
        title: "Error in increasing quantity",
        confirmButtonColor: "#56b124"
      })
    })
  }
  const clearCart = () => {
    for (let i = 0; i < tableData.length - 1; i++) {
      const data = new FormData();
      data.append("customer", apiKey['id'])
      data.append("price_list", tableData[i]['price_list'])
      axios.post(`${apiUrl}/cart/remove_from_cart/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(() => {
        setUpdated(!updated)
      }).catch(() => {
        Swal.fire({
          title: "Error in clearing cart",
          confirmButtonColor: "#56b124"
        })
      })
    }
  }

  // delete cart item
  const deleteCartItem = (price_list,eachItemIndex) => {

    const data = new FormData();
    data.append("customer", apiKey['id'])
    data.append("price_list", price_list)
    axios.post(`${apiUrl}/cart/remove_from_cart/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(() => {
      dispatch(cartReducerActions.remove(eachItemIndex));
      setUpdated(!updated)
      Swal.fire({
        title: "Item removed successfully",
        confirmButtonColor: "#56b124"
      })
    }).catch(() => {
      Swal.fire({
        title: "Error in removing item",
        confirmButtonColor: "#56b124"
      })
    })
  };

  return (
    <>
      {(() => {
        if (tableData.length > 1)
          return (
            <>
              <button className="clearCartBtn" onClick={clearCart}>Clear Cart</button>
              <div className="cart__table">
                <p>(Scroll left-right to see the full table)</p>
                <table>
                  {/* <thead> */}
                  <tr>
                    <th>Scrap Name</th>
                    <th>Price (Rs)</th>
                    <th>Approx. Quantity</th>
                    <th>Approx. Total (Rs)</th>
                    <th>Action</th>
                  </tr>
                  {/* </thead> */}
                  {tableData.map((eachItem, eachItemIndex) => {
                    total = eachItem.total;
                    if (eachItem.price_list > 0)
                      return (
                        <>
                        {/* <tbody> */}
                          <tr key={eachItemIndex}>
                            <td>{eachItem.subcategory_name}</td>
                            <td>{eachItem.price}</td>
                            <td>
                              <button onClick={(e) => decrement(e, eachItem.price_list)}><IndeterminateCheckBoxIcon /></button>
                              {eachItem.quantity}
                              <button onClick={(e) => increment(e, eachItem.price_list)}><AddBoxIcon /></button>
                            </td>
                            <td>{eachItem.subtotal}</td>
                            <td>
                              {
                                <button onClick={() => deleteCartItem(eachItem.price_list,eachItemIndex)}><DeleteForeverIcon /></button>
                              }
                            </td>
                          </tr>
                          {/* </tbody> */}
                        </>
                        
                      );
                  })}
                </table>
                <h1> Approx. Grand Total : <span>{total}</span></h1>
                {/* <span>{cart.reduce((acc, curr) => acc + curr.totalPrice, 0)} Rs</span> */}
              </div>
            </>
          );
        else return (
          <div className="empty__cart">
            <h1>Your cart is empty</h1>
            <button
              className="cart__button"
              onClick={() => {
                dispatch(stepReducerActions.reset("cartStep"));
                history.push("/sell");
              }}
            >
              Go to sell page
            </button>
          </div>
        );
      })()}
    </>
  );
};

export default CartTable;
