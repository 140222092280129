import React from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

// css
import "../../App.css";
import { apiUrl } from "../../Private";

const SellCard = (props) => {
  const history = useHistory();

  // const apiKey = JSON.parse(localStorage.getItem("KTMauth"));
  // const gAuth = JSON.parse(localStorage.getItem("KTMgauth"));
  const pincode = localStorage.getItem("KTMpincode");

  //extra for localhost as well
  const image_url = `${apiUrl}${props.pic}`;

  return (
    <button
      className="scrap__section__card"
      onClick={() => {
        // if (pincode !== null) {
        //   if (apiKey || gAuth) {
        //     localStorage.setItem("KTMsellItemName", props.title.toLowerCase());
        //     history.push("/sell/sellitem");
        //   } else {
        //     history.push("/signin");
        //   }
        // }
        if (pincode !== null) {
          localStorage.setItem("KTMsellItemName", props.title.toLowerCase());
          history.push("/sell/sellitem");
        }
        else Swal.fire({
          title: "Enter Pincode",
          confirmButtonColor: "#56b124",
        })
      }}
    >
      <img src={image_url} alt="" />
      <p>{props.title[0].toUpperCase() + props.title.slice(1).toLowerCase()}</p>
    </button>
  );
};

export default SellCard;
