// image
import wallet from "../../Image/wallet.png";

export const WalletHistoryData = [
  // {
  //   paymentTypeImg: wallet,
  //   transactionId: "0123456789",
  //   transferTo: "Google Pay",
  //   mobileNo: "8944837858",
  //   amount: "360 Rs",
  //   date: "22th June 2021",
  // },
  // {
  //   paymentTypeImg: wallet,
  //   transactionId: "0123456789",
  //   transferTo: "Phone Pe",
  //   mobileNo: "8944837858",
  //   amount: "360 Rs",
  //   date: "22th June 2021",
  // },
  // {
  //   paymentTypeImg: wallet,
  //   transactionId: "0123456789",
  //   transferTo: "Paytm",
  //   mobileNo: "8944837858",
  //   amount: "360 Rs",
  //   date: "22th June 2021",
  // },
];
