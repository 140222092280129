import React from "react";

// css
import "../App.css";

const SellFaqTopBanner = (props) => {
  return (
    <div className="sell__faq__top__banner">
      <h1>{props.title}</h1>
    </div>
  );
};

export default SellFaqTopBanner;
