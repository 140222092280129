import React from 'react'

// css
import "../../../Css/UserAddressCard.css";

const UserAddressCard = (props) => {

  return (
    <>
      <div className="user__area__card">
        <p>
          Address : <span>{props.area}</span>
        </p>
        <p>
          Area : <span>{props.address_area}</span>
        </p>
        <p>
          Landmark : <span>{props.landmark}</span>
        </p>
        <p>
          City : <span>{props.city}</span>
        </p>
        <p>
          State : <span>{props.state}</span>
        </p>
        <p>
          Pincode : <span>{props.pincode}</span>
        </p>
        <p>
          Default : <span>{props.default}</span>
        </p>
        <button onClick={props.deleteArea}>Delete Area</button>
      </div>
    </>
  )
}

export default UserAddressCard