import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

// css
import './App.css';

// private route
import PrivateCustomerRoute from './Route/PrivateCustomerRoute';
import PrivateDealerRoute from './Route/PrivateDealerRoute';
// gadbad routes
import PrivateSellItemRoute from './Route/PrivateSellItemRoute';
import PrivateAuthRoute from './Route/PrivateAuthRoute';

// component
import About from "./Component/AboutPage/About";
import JoinUs from './Component/JoinUsPage/JoinUs';
import Sell from './Component/SellPage/Sell';
import Faq from './Component/FaqPage/Faq';
import Contact from "./Component/ContactPage/Contact";
import PrivacyPolicy from './Component/Privacy&Terms/PrivacyPolicy';
import TermsConditions from './Component/Privacy&Terms/TermsConditions';

import SignIn from './Component/SignIn/SignIn';
import SignUp from './Component/SignUp/SignUp';
import CustomerSignUp from './Component/SignUp/CustomerSignUp/CustomerSignUp';
import CustomerSignUpPersonal from './Component/SignUp/CustomerSignUp/CustomerSignUpPersonal/CustomerSignUpPersonal';
import CustomerSignUpOrganization from './Component/SignUp/CustomerSignUp/CustomerSignUpOrganization/CustomerSignUpOrganization';
import DealerSignUp from './Component/SignUp/DealerSignUp/DealerSignUp';
import Verification from './Component/SignUp/Verification';
import ForgotPassword from './Component/ForgotPassword/ForgotPassword';
import Extra from './Component/ForgotPassword/Extra';
import ForgetLinkStep2 from './Component/ForgotPassword/ForgetLinkStep2';

import SellItem from './Component/SellItemPage/SellItem';
import CartSection from './Component/Cart/CartSection';
import UserProfile from './Component/User/UserProfile/UserProfile'
import UserProfileEdit from './Component/User/UserProfile/UserProfileEdit'
import UserPickup from './Component/User/UserPickup/UserPickup'
import UserWallet from './Component/User/UserWallet/UserWallet'
import UserWalletHistory from './Component/User/UserWallet/UserWalletHistory'
import UserQR from './Component/User/UserQR/UserQR'
import UserAutoScrap from './Component/User/UserAutoScrap/UserAutoScrap'

import DealerHome from './Component/Dealer/DealerHome/DealerHome';
import DealerProfile from './Component/Dealer/DealerProfile/DealerProfile';
import DealerQR from './Component/Dealer/DealerQR/DealerQR';
import DealerPickup from './Component/Dealer/DealerPickup/DealerPickup';
import DealerWallet from './Component/Dealer/DealerWallet/DealerWallet';
import DealerSettings from './Component/Dealer/DealerSettings/DealerSettings';
import DealerDocumentUpload from './Component/Dealer/DealerSettings/DealerDocumentUpload/DealerDocumentUpload';
import DealerArea from './Component/Dealer/DealerSettings/DealerArea/DealerArea';
import DealerSetPrice from './Component/Dealer/DealerSettings/DealerSetPrice/DealerSetPrice';
import DealerEditPrice from './Component/Dealer/DealerSettings/DealerSetPrice/DealerEditPrice';
import DealerPriceList from './Component/Dealer/DealerSettings/DealerSetPrice/DealerPriceList';
import DealerRequestCategory from './Component/Dealer/DealerSettings/DealerRequestCategory/DealerRequestCategory';

import MarketPlace from './Component/MarketPlace/MarketPlace';
import MarketPlaceSell from './Component/MarketPlace/MarketPlaceSell';
import Address from './Component/Address';
import UserAddress from './Component/User/UserProfile/UserAddress';
import DealerProfileEdit from './Component/Dealer/DealerProfile/DealerProfileEdit';

const App = () => {
  return (
    <Router>
      <Switch>
      <Route exact path="/"><About /></Route>
      <Route exact path="/joinus"><JoinUs /></Route>
      <Route exact path="/sell"><Sell /></Route>
      <Route exact path='/sell/sellitem' component={SellItem}></Route>
      <Route exact path="/faq"><Faq /></Route>
      <Route exact path="/contact"><Contact /></Route>
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/termsconditions" component={TermsConditions} />

      {/* signin and signup */}
      <PrivateAuthRoute exact path="/signin" component={SignIn}></PrivateAuthRoute>
      <PrivateAuthRoute exact path='/signin/signup' component={SignUp}></PrivateAuthRoute>
      <PrivateAuthRoute exact path='/signin/signup/customer' component={CustomerSignUp}></PrivateAuthRoute>
      <PrivateAuthRoute exact path='/signin/signup/customer/personal' component={CustomerSignUpPersonal}></PrivateAuthRoute>
      <PrivateAuthRoute path='/signin/signup/customer/organization' component={CustomerSignUpOrganization}></PrivateAuthRoute>
      <PrivateAuthRoute exact path='/signin/signup/dealer' component={DealerSignUp}></PrivateAuthRoute>


       {/* frontendUrl */}
      <Route exact path='/v3/api/email-verify/:utype/Frontend/'><Verification /></Route>
      <Route exact path="/forget_password"><ForgotPassword /></Route>
      <Route exact path="/v3/api/password-reset-confirm/:utype/:uid/:token/Frontend"><Extra /></Route>
      <Route exact path='/password_change_success'><ForgetLinkStep2 /></Route>

      {/* customer Routes */}

      <PrivateCustomerRoute exact path='/sell/cart' component={CartSection}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/profile' component={UserProfile}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/address' component={UserAddress}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/profile/profileedit' component={UserProfileEdit}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/pickup' component={UserPickup}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/qr' component={UserQR}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/autoscrap' component={UserAutoScrap}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/wallet' component={UserWallet}></PrivateCustomerRoute>
      <PrivateCustomerRoute exact path='/sell/user/wallet/wallethistory' component={UserWalletHistory}></PrivateCustomerRoute>

      {/* dealer urls */}
      <PrivateDealerRoute exact path='/dealer/home' component={DealerHome}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/profile' component={DealerProfile}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/profile/profileedit' component={DealerProfileEdit}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/profile/qr' component={DealerQR}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/pickup' component={DealerPickup}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/wallet' component={DealerWallet}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings' component={DealerSettings}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/addarea' component={DealerArea}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/setprice' component={DealerSetPrice}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/setprice/editprice' component={DealerEditPrice}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/setprice/editprice/pricelist' component={DealerPriceList}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/documentupload' component={DealerDocumentUpload}></PrivateDealerRoute>
      <PrivateDealerRoute exact path='/dealer/settings/requestcategory' component={DealerRequestCategory}></PrivateDealerRoute>

      {/* market place (no authentication and routes needed) */}
      <Route exact path='/marketplace'><MarketPlace /></Route>
      <Route exact path='/marketplace/:ktid/'><MarketPlaceSell /></Route>

      <Route exact path='/address'><Address /></Route>
      
      <Redirect to="/" />
      </Switch>
    </ Router>
    
  );
}

export default App;

